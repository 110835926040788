<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col class="pb-0">
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="sales-report-custom"
              :show-labels="false"
              :districts="filteringPanel.districts"
              :filters="filteringPanel.filters"
              :dimensions="filteringPanel.dimensions"
              :measures="filteringPanel.measures"
              @search="getData"
              @change="filterData"
              :load-dictionaries="loadDictionaries"
              :loaded="filteringPanel.loaded"
              @async-search="onAsyncSearch"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
              showLabels="true"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <table-custom
              ref="dataTable"
              :name="`custom_table_sales_report_custom`"
              v-show="dataTable.visible"
              :loading="dataTable.isLoading"
              :data="dataTable.dataSet"
              :options="dataTable.options"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";
import TableCustom from "@/components/TableCustom";

export default {
  name: "SalesReportCustom",
  components: {
    FilteringPanel,
    TableCustom
  },
  data: function() {
    return {
      isLoading: false,
      filteringPanel: {
        selected: {},
        loaded: false,
        districts: [],
        filters: [
          {
            type: "select",
            dataType: "territory",
            title: "Territory",
            tooltip: "Account billing territory",
            name: "territory",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Country",
            tooltip: "Account billing country",
            name: "country",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "State",
            tooltip: "Account billing state",
            name: "state",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "City",
            tooltip: "Account billing city",
            name: "city",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Account",
            tooltip: "Full account name",
            name: "account",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {},
            async: true,
            loading: false,
            startsWith: false
          },
          {
            type: "select",
            dataType: "string",
            title: "User",
            tooltip: "KOVAL user who entered account",
            name: "user",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Product",
            tooltip: "KOVAL product sold",
            name: "product",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Account Category",
            tooltip: "Account premise or status",
            name: "category",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "This quarter",
            title: "Sales Period",
            tooltip: "Sales period",
            name: "period",
            options: []
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "Last quarter",
            title: "Zero Sales Period",
            tooltip: "Zero sales period",
            name: "zsPeriod",
            options: []
          }
        ],
        dimensions: {
          type: "select",
          dataType: "string",
          title: "Dimensions",
          tooltip:
            "Specify table rows. Metrics will be calculated for each unique combination of dimensions",
          name: "dimensions",
          trackby: "id",
          label: "label",
          multiple: true,
          options: [
            /*  {
                              id: 0,
                              label: "Territory"
                          },*/
            {
              id: 1,
              label: "Account"
            },
            {
              id: 2,
              label: "Country"
            },
            {
              id: 3,
              label: "State"
            },
            {
              id: 4,
              label: "City"
            },
            {
              id: 5,
              label: "Address"
            },

            {
              id: 6,
              label: "User"
            },
            {
              id: 7,
              label: "Category"
            },
            {
              id: 8,
              label: "Product"
            },
            {
              id: 9,
              label: "Year"
            },
            {
              id: 10,
              label: "Month"
            },
            {
              id: 11,
              label: "Account Email"
            },
            {
              id: 12,
              label: "Account Phone"
            },
            {
              id: 13,
              label: "Account Contacts"
            },
          ],
          placeholder: "Select dimensions",
          selected: {}
        },
        measures: {
          type: "select",
          dataType: "string",
          title: "Measures",
          tooltip:
            "Specify table columns. Metrics will be calculated for each unique combination of dimensions",
          name: "measures",
          trackby: "id",
          label: "label",
          multiple: true,
          options: [
            {
              id: 0,
              label: "Bottles",
              style: {
                style: "decimal"
              }
            },
            {
              id: 1,
              label: "Cases",
              style: {
                style: "decimal"
              }
            },
            {
              id: 2,
              label: "Spent",
              style: {
                style: "decimal"
              }
            },
            {
              id: 3,
              label: "First invoice"
            },
            {
              id: 4,
              label: "Last invoice"
            },
            {
              id: 5,
              label: "Total sales",
              style: {
                style: "currency",
                currency: "USD"
              }
            },
            {
              id: 6,
              label: "Average invoice sales",
              style: {
                style: "currency",
                currency: "USD"
              }
            },
            {
              id: 7,
              label: "Total invoice count"
            }
          ],
          placeholder: "Select measures",
          selected: {}
        }
      },
      dataTable: {
        isLoading: false,
        visible: true,
        dataSet: [],
        options: {
          columns: [],
          filterable: false,
          formatColumns: [
            // {name: 'Unit Price', style:{ style: 'currency', currency: 'USD' }},
            // {name: 'Qty/Unit', style:{ style: 'decimal'}},
          ],
          footerHeadings: false,
          perPage: 50,
          showActions: false
        }
      },
      districts: []
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onAsyncSearch(payload) {
      if (payload.filterName === "account") {
        let accountsFilter = this.filteringPanel.filters.find(
          f => f.name === "account"
        );

        accountsFilter.loading = true;

        this.$api
          .post("dictionaries/accounts", {
            query: payload.query,
            starts_with: payload.startsWith
          })
          .then(response => {
            accountsFilter.loading = false;

            accountsFilter.options = response.map(u => ({
              id: u.id,
              label: u.name
            }));
          });
      }
    },
    async loadDictionaries() {
      let self = this;

      const territories = async () => {
        let response = await this.$api.get("dictionaries/territories");

        self.filteringPanel.filters.find(
          f => f.name === "territory"
        ).options = response.map(u => ({
          id: u.ID,
          label: u.Name
        }));
      };

      const users = async () => {
        let response = await this.$api.get("users");
        self.filteringPanel.filters.find(
          f => f.name === "user"
        ).options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));
      };

      const products = async () => {
        let response = await this.$api.get("dictionaries/products");
        self.filteringPanel.filters.find(
          f => f.name === "product"
        ).options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      const categories = async () => {
        let response = await this.$api.get(
          "dictionaries/accounts/categories/vendors"
        );
        self.filteringPanel.filters.find(
          f => f.name === "category"
        ).options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      const districts = async () => {
        let response = await this.$api.post("dictionaries/countries/mapped", {
          ignore_restrictions: false
        });

        self.districts = response;

        self.filteringPanel.districts = self.districts;

        self.filteringPanel.filters.find(
          f => f.name === "country"
        ).options = self.$helpers.getDistinctArray(
          response,
          "country",
          "country",
          "id",
          "label"
        );
      };

      Promise.all([
        territories(),
        users(),
        products(),
        categories(),
        districts()
      ]).then(() => {
        this.filteringPanel.loaded = true;
      });
    },
    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },

    onFilteringPanelLoad() {
      this.getData();
    },
    filterData: function(e) {
      //let self = this;

      this.filteringPanel.selected = e;
    },
    onCustomFilterSelected() {
      this.getData();
    },

    getData: function() {
      let self = this;

      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        territories: f.territory ? f.territory : [],
        countries: f.country ? f.country.map(i => i.label) : [],
        states: f.state ? f.state.map(i => i.label) : [],
        cities: f.city ? f.city.map(i => i.label) : [],
        users: f.user ? f.user.map(i => i.label) : [],
        accounts: f.account ? f.account.map(i => i.label) : [],
        products: f.product ? f.product.map(i => i.label) : [],
        categories: f.category ? f.category.map(i => i.label) : [],
        dimensions: f.dimensions
          ? f.dimensions.map(i => "`" + i.label + "`")
          : [],
        measures: f.measures ? f.measures.map(i => "`" + i.label + "`") : [],
        period: f.period,
        zsPeriod: f.zsPeriod
      };

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      this.$api.data
        .getSalesReportCustom(payload)
        .then(response => {
          self.dataTable.isLoading = false;

          if (response.length === 0) return;

          //get style from selected dropdowns
          if (fp.selected.measures)
            self.dataTable.options.formatColumns = fp.selected.measures
              .filter(m => m.style)
              .map(m => ({
                name: m.label,
                style: m.style
              }));
          //or from  selected expression
          else if (fp.expression.json.find(e => e.fieldName === "measures"))
            self.dataTable.options.formatColumns = fp.expression.json
              .find(e => e.fieldName === "measures")
              .rules[0].selectedValues.filter(m => m.style)
              .map(m => ({
                name: m.label,
                style: m.style
              }));

          let o = response.find(o => o !== {});
          self.dataTable.options.columns = Object.getOwnPropertyNames(o);
          self.dataTable.dataSet = response;
        })
        .catch(error => {
          console.log(error);
          self.dataTable.isLoading = false;
          self.$form.msgBoxOk("Error occured");
        });
    }
  },
  watch: {
    "filteringPanel.selected.country": function(newVal) {
      if (!newVal) return;

      this.filteringPanel.filters.find(
        f => f.name === "state"
      ).options = this.$helpers.getDistinctArray(
        this.districts.filter(s =>
          newVal.map(l => l.label).includes(s.country)
        ),
        "state",
        "state",
        "id",
        "label"
      );
    },
    "filteringPanel.selected.state": function(newVal) {
      if (!newVal) return;
      this.filteringPanel.filters.find(
        f => f.name === "city"
      ).options = this.$helpers.getDistinctArray(
        this.districts.filter(s => newVal.map(l => l.label).includes(s.state)),
        "city",
        "city",
        "id",
        "label"
      );
    }
  }
};
</script>

<style>
.products-container {
  height: 400px;
  overflow-y: scroll;
}

.accounts-container {
  height: 400px;
  overflow-y: scroll;
  cursor: pointer;
}

[v-cloak] {
  display: none;
}
</style>
